import React, { useEffect, useState } from 'react'
import { LoadingOverlay } from '../../common'
import { AuthFlowWrapper } from '../layout'
import { Paths } from '../../../constants/structure'
import { navigate } from 'gatsby'

import * as styles from './accountVerify.module.scss'
import useAccountVerify from '../../../hooks/useAccountVerify/useAccountVerify'
import { useLocation } from '@reach/router'

const AccountVerify = () => {
   const { search } = useLocation()

   const {
      isLoading,
      validResponse: userVerified,
      setVerificationOptions,
      verificationOptions,
   } = useAccountVerify()

   useEffect(() => {
      if (!!search) {
         const params = new URLSearchParams(search)
         const id = params.get('id')
         const token = params.get('token')
         const plantype = params.get('plantype')
         setVerificationOptions({ id, token, plantype })
      }
   }, [search])

   useEffect(() => {
      if (userVerified) {
         const confirmationPath =
            verificationOptions?.plantype === 'starter'
               ? Paths.REGISTER_STARTER_CONFIRMATION
               : Paths.REGISTER_TRIAL_CONFIRMATION
         navigate(confirmationPath)
      }
   }, [userVerified])

   return (
      <AuthFlowWrapper
         heading={
            !userVerified && !isLoading
               ? `Sorry we were unable to verify your account.`
               : ''
         }
      >
         {isLoading || userVerified ? (
            <LoadingOverlay />
         ) : (
            <div className={styles.helpInstruction}>
               <div>No email? Check your spam folder.</div>
               <div>Need help? Call (866) 768-TOOL option 2.</div>
            </div>
         )}
      </AuthFlowWrapper>
   )
}
export default AccountVerify
